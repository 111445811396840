<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      :items-per-page="15"
      class="elevation-0"
      :search="search"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          :label="$t('labels.search')"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:[`item.course`]="{ item }">
        {{ item.course }}
      </template>
      <template v-slot:[`item.progress`]="{ item }">
        <div class="user-progress">
          <v-progress-circular
            :rotate="-90"
            :size="25"
            :width="4"
            :value="item.progress"
            color="primary"
          >
          </v-progress-circular>
          <div class="user-progress__percent caption">{{ item.progress }}%</div>
        </div>
      </template>
      <template v-slot:[`item.done`]="{ item }">
        <v-icon v-if="item.done">mdi-check</v-icon>
      </template>
      <template v-slot:[`item.done_date`]="{ item }">
        <div v-if="item.done">
          {{ item.done_date ? $df.datetime(item.done_date) : '' }}
        </div>
      </template>
      <template v-slot:[`item.certificate`]="{ item }">
        <div v-if="item.done">
          <v-btn icon @click.prevent="$emit('downloadCertificate', item._id)">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$tt.capitalize(this.$t('reports.student.course')),
          align: 'start',
          value: 'course',
        },
        {
          text: this.$tt.capitalize(this.$t('reports.student.progress')),
          align: 'start',
          value: 'progress',
        },
        {
          text: this.$tt.capitalize(this.$t('reports.student.done')),
          align: 'start',
          value: 'done',
        },
        {
          text: this.$tt.capitalize(this.$t('reports.student.doneDate')),
          align: 'start',
          value: 'done_date',
        },
        {
          text: this.$tt.capitalize(this.$t('labels.downloadCertificate')),
          align: 'start',
          value: 'certificate',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.user-progress {
  display: flex;
  align-items: center;
  &__percent {
    margin-left: 7px;
    font-weight: 700;
  }
}
</style>
