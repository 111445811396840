import { render, staticRenderFns } from "./ReportStudentView.vue?vue&type=template&id=e873c426&scoped=true"
import script from "./ReportStudentView.vue?vue&type=script&lang=js"
export * from "./ReportStudentView.vue?vue&type=script&lang=js"
import style0 from "./ReportStudentView.vue?vue&type=style&index=0&id=e873c426&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e873c426",
  null
  
)

export default component.exports