<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks" />

    <f-container topBottom>
      <v-row v-if="isLoading">
        <v-col cols="12">
          <v-skeleton-loader class="mx-auto" type="table"> </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-if="!isLoading">
        <v-col cols="12" md="4" lg="3">
          <dashboard-counter
            color="#1976D2"
            icon="mdi-format-list-bulleted"
            :count="stats.total"
          >
            {{ $t('reports.student.totalCourses') }}
          </dashboard-counter>
        </v-col>
        <v-col cols="12" md="4" lg="3">
          <dashboard-counter
            color="#388E3C"
            icon="mdi-check"
            :count="stats.done"
          >
            {{ $t('reports.student.doneCourses') }}
          </dashboard-counter>
        </v-col>
        <v-col cols="12">
          <report-student-view
            :list="list"
            @downloadCertificate="onDownloadCertificate"
          />
        </v-col>
      </v-row>
    </f-container>
  </div>
</template>

<script>
import ReportStudentView from '../../components/Reports/ReportStudentView.vue';
import DashboardCounter from '../../components/DashboardCounter.vue';
import courseUtils from '../../helpers/course_utils';
import fileUtils from '../../helpers/file_utils';

export default {
  data() {
    return {
      belongsToTeam: Boolean,
    };
  },
  components: {
    ReportStudentView,
    DashboardCounter,
  },
  props: {
    id: String,
  },
  mounted() {
    // this.belongsToTeam = this.$route.params.belongsToTeam;
    this.$store.dispatch('report/studentView', this.$route.params.id);
  },
  computed: {
    prevLinks() {
      return [
        {
          title: this.$t('labels.reports'),
          route: {
            name: 'report-list-students',
          },
        },
      ];
    },
    title() {
      return this.student.name;
    },
    isLoading() {
      const { report } = this.$store.state;
      return report.loading;
    },
    student() {
      const { report } = this.$store.state;
      if (!report.studentView.student) {
        return {};
      }
      return {
        _id: report.studentView.student._id,
        name: report.studentView.student.name,
      };
    },
    list() {
      const { report } = this.$store.state;
      if (!report.studentView.courses) {
        return [];
      }
      return report.studentView.courses.map(item => {
        return {
          _id: item.course._id,
          course: courseUtils.courseTitle(item.course, this.$i18n.locale),
          progress: item.progress.progress,
          done: item.progress.done,
          done_date: item.progress.done_date,
        };
      });
    },
    stats() {
      const { report } = this.$store.state;
      if (!report.studentView.stats) {
        return {
          total: 0,
          done: 0,
        };
      }
      return {
        total: report.studentView.stats.total,
        done: report.studentView.stats.done,
      };
    },
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    async onDownloadCertificate(courseID) {
      const { studentView } = this.$store.state.report;
      const courseItem = studentView.courses.find(item => {
        return item.course._id === courseID;
      });
      const result = await this.$store.dispatch('user/generateCertificate', {
        courseID: courseID,
        userID: studentView.student._id,
      });
      if (result) {
        const { course } = courseItem;
        const name = courseUtils.certificateFilename(course, this.$i18n.locale);
        fileUtils.download({
          name,
          type: 'application/pdf',
          data: result,
          isBase64: true,
        });
      } else {
        this.$store.commit('snackbar/show', {
          content: this.$t('reports.student.certificateError'),
          type: 'error',
        });
      }
    },
  },
};
</script>
