<template>
  <v-card :color="color" dark>
    <v-card-text>
      <div class="dashboard-counter">
        <div class="dashboard-counter__icon">
          <v-icon :color="color">{{ icon }}</v-icon>
        </div>
        <div class="dashboard-counter__info">
          <div class="dashboard-counter__title">
            <slot></slot>
          </div>
          <div class="dashboard-counter__count">{{ count }}</div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: String,
    count: [String, Number],
    color: {
      type: String,
      default: "#333",
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-counter {
  display: flex;
  align-items: flex-start;
  line-height: 1.2;
  &__icon {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 20px;
    width: 60px;
    height: 60px;
    background: white;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    .v-icon {
      font-size: 32px;
    }
  }
  &__info {
    flex-grow: 1;
    flex-shrink: 0;
  }
  &__title {
    font-size: 18px;
    margin: 0 0 5px 0;
  }
  &__count {
    font-size: 56px;
  }
}
</style>
